import React from "react";
import Helmet from "react-helmet";

import { Link } from 'gatsby';

import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";

import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import comingSoonStyle from "assets/jss/material-kit-react/views/comingSoonStyle.jsx";

import WriteHere from "../HomePage/SubSections/WriteHere.jsx";
import EditHere from "../HomePage/SubSections/EditHere.jsx";
import FormatHere from "../HomePage/SubSections/FormatHere.jsx";



const dashboardRoutes = [];

class ComingSoon extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
      <Helmet>
        <title>BookMerlin</title>
      </Helmet>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="BookMerlin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "#009688"
          }}
          {...rest}/>
        {/*}<Parallax filter image={require("assets/img/background.jpg")} >
        <Parallax filter image={require("assets/img/backgroundShadedGray.png")}
        <Parallax filter image={require("assets/img/backgroundShaded.png")}*/}

        <Parallax filter image={require("assets/img/backgroundShaded.jpg")} style={{
          alignItems:'unset'
        }}>
          <div className={classes.container}>
            <div className={classes.section}>

            <GridContainer className={classes.gridContainer} justify='space-between'>


            <GridItem xs={12} sm={12} md={6} className={classes.gridItem}>
              {/*<div className={classes.float}>
                    <h2 className={classes.floatHeader+' '+classes.floatWrapper}>
                      <div  className={classes.headlineText}> </div>
                      <div className={classes.headlineText}>Ever ask yourself, why do I have to juggle so many copies of my manuscript?</div>
                      <div style={{
                        paddingLeft:'1em'
                      }}>
                        <div  className={classes.headlineBullet}>&bull; One for writing</div>
                        <div  className={classes.headlineBullet}>&bull; One for your editor</div>
                        <div  className={classes.headlineBullet}>&bull; One for formatting</div>
                      </div>

                    </h2>
                    <h4 className={classes.floatDescription}>

                    </h4>
              </div>*/}

              <div className={classes.sidelineMainLeft}>
                  <div classNamex={classes.headlineMain} style={{
                    fontSize:'2.0em',
                    borderx:'1px solid yellow',
                    lineHeight:'1.2',
                    fontWeight:'500',
                    textAlign:'left'
                  }}>WELCOME TO THE BOOKMERLIN BETA PROGRAM</div>


                  <div className={classes.headlineMainContent} style={{

                  }}>Here are the beta program details...</div>







                    {/*}<div classNamex={classes.headlineMain} style={{
                        fontSize:'2.0em',
                        borderx:'1px solid yellow',
                        lineHeight:'1.2',
                        fontWeight:'500'
                      }}>JUST ONE</div>*/}

                      {/*<div classNamex={classes.headlineMain} style={{
                          fontSize:'2.0em',
                          borderx:'1px solid yellow',
                          lineHeight:'1.2',
                          fontWeight:'500',
                          marginTop:'2em'
                        }}>WRITE &bull; EDIT &bull; FORMAT</div>

                        <div classNamex={classes.headlineMain} style={{
                            fontSize:'2.0em',
                            borderx:'1px solid yellow',
                            lineHeight:'1.2',
                            fontWeight:'500'
                          }}>HERE</div>*/}
                </div>




            </GridItem>
              <GridItem xs={12} sm={12} md={6}  className={classes.gridItem}>
                {/*<div className={classes.launch}>
                      <h2 className={classes.launchHeader}>
                        BookMerlin Beta Launch!
                      </h2>
                      <h4 className={classes.launchDescription}>
                        Get your inviation!
                      </h4>
                </div>*/}

                {/*<h1 className={classes.floatHeader}>

                  BookMerlin Beta is almost here!
                </h1>*/}



              </GridItem>
              {/*}<GridItem xs={12} sm={12} md={12}>

                <div className={classes.headlineMain}>Write, Edit and Format. Here.</div>
                <div className={classes.headlineSubMain}>And create boxsets, and share styles...</div>

              </GridItem>*/}
              </GridContainer>
            </div>
            </div>
          </Parallax>
          <div className={classNames(classes.main, classes.mainRaised)}>
          <GridContainer justify="center">
            {/*}<GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>BookMerlin Beta Launch!</h2>
              <h5 className={classes.description}>
                Launching soon!
              </h5>
            </GridItem>*/}

            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>Features</h2>
              <h5 className={classes.description}>
                Many of our features are described below.
              </h5>
            </GridItem>
            <GridItem >
                <WriteHere />
                <EditHere />
                <FormatHere />
            </GridItem>
          </GridContainer>
          </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(comingSoonStyle)(ComingSoon);
